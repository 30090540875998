import { render, staticRenderFns } from "./radio-group.vue?vue&type=template&id=0d2ecbca&scoped=true&"
import script from "./radio-group.vue?vue&type=script&lang=js&"
export * from "./radio-group.vue?vue&type=script&lang=js&"
import style0 from "./radio-group.vue?vue&type=style&index=0&id=0d2ecbca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d2ecbca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VRadio,VRadioGroup,VSheet})
