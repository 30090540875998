<template>
  <v-radio-group
    v-model="selected"
    hide-details
    :column="column"
    :disabled="disabled"
    :readonly="readonly"
    :ripple="false"
  >
    <v-sheet
      v-for="(n, i) in items"
      class="mt-n1 pa-1 mr-6"
      rounded="pill"
      elevation="0"
      style="box-shadow: none !important"
      :color="selected == n ? '#e2f0f8' : 'white' "
      :key="i"
    >
      <v-radio
        color="primary"
        class="pr-3"
        on-icon="mdi-checkbox-marked-circle"
        off-icon="mdi-checkbox-blank-circle-outline"
        :ripple="false"
        :value="n"
      >
        <template v-slot:label>
          <span
            :class="{
              label: true,
              nowrap: true,
              'primary--text': selected == n
            }"
          >
            {{ n }}
          </span>
        </template>
      </v-radio>
    </v-sheet>
  </v-radio-group>
</template>

<style scoped>
  .v-input--selection-controls{
    margin: 0 !important;
    padding: 0.28rem 0 0;
  }
  .label {
    font-size: 0.90rem !important;
    /*font-weight: bold;*/
  }
</style>

<script>
export default {
  props: {
    column: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      default: 'nofield'
    },
    items: {
      type: Array,
      default() { return ['1', '2'] }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: [ String, Number ]
    }
  },
  computed: {
    selected:{
      get: function() {
        return this.value
      },
      set: function(value) {
        const field = this.field
        // const value = this.items.find(i => i.value === key)
        this.$emit('change', { field, value })
      }
    }
  }
}
</script>
