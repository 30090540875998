<template>
  <v-navigation-drawer
    v-model="documentDrawer"
    app
    right
    temporary
    :stateless="stateless"
    :width="width"
    style="-webkit-filter: blur(0)"
  >
    <template v-slot:prepend>
      <v-toolbar
        :color="color"
        dark
        dense
        flat
        class="d-print-none px-1"
      >
        <v-icon
          class="mr-3"
        >
          {{ icon }}
        </v-icon>

        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <slot name="actionMenu"/>
          <v-tooltip
            bottom
            color="accent"
            transition="fade-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                depressed
                icon
                tile
                :ripple="false"
                class="mr-n5 px-4"
                @click="documentDrawer=false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
    </template>
    <div
      v-if="!printView"
      class="pb-16"
    >
      <slot
        name="default"
      />
    </div>
    <slot
      v-else
      name="print"
    />
    <keep-alive>
    </keep-alive>
  </v-navigation-drawer>
</template>

<script>

export default {
  components: {
  },
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    hidePrint: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'mdi-text-box'
    },
    open: {
      type: Boolean,
      default: false
    },
    stateless: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 900
    },
    title: {
      type: String,
      default: 'overlay-window'
    }
  },
  computed: {
    documentDrawer: {
      get () {
        return this.open
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  data () {
    return {
      printView: false
    }
  }
}
</script>
