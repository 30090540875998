<template>
  <v-container
    fluid
    class="pa-0 text-body-1"
  >
    <v-row
      no-gutters
      class="mx-n4"
    >
      <v-col
        v-for="n in 2"
        class="px-5"
        cols="6"
        :key="n"
      >
        <template
          v-for="(field, i) in printableFields"
        >
          <div
            v-if="field.column === n"
            :key="i"
            class=""
            cols="12"
            :class="`d-flex item pt-1 pb-1 px-0 mt-${field.mt}`"
          >
            <div class="finding-label font-weight-bold">
              <div :class="{
                'primary--text': finding[field.field],
                'text--caption': finding[field.field],
                'text--disabled': !finding[field.field]
              }">
                {{ field.field | humanizeFieldName }}
              </div>
            </div>
            <div
              :class="{
                'finding-value': true,
                'text--disabled': !finding[field.field]
              }"
            >
              <div v-if="finding[field.field] instanceof Date">
                {{ formatDate(finding[field.field]) }}
              </div>
              <div v-else>
                {{ finding[field.field] || '-'}}</div>
              </div>
          </div>
        </template>
      </v-col>
    </v-row>
    <v-row
      class="mx-n4 my-0"
      no-gutters
    >
      <v-col
        cols="12"
        class="px-5 my-0"
      >
        <div class="d-flex item" />
        <div class="d-flex px-0 item my-2">
          <div class="finding-label text--caption primary--text font-weight-bold">
            Finding Text
          </div>
          <slot name="acbFinding_Text" :finding="finding">
            <div class="finding-value">{{ finding.acbFinding_Text }}</div>
          </slot>
        </div>
      </v-col>

      <slot name="proposed" :finding="finding">
        <v-col
          cols="12"
          class="px-5 my-0"
        >
          <div class="d-flex item" />
          <div class="d-flex px-0 item my-2">
            <div class="finding-label text--caption primary--text font-weight-bold">
              Proposed Corrective Action / Root Cause Analysis
            </div>
            <div class="finding-value">{{ finding.acbProposedCA || '-' }}</div>
          </div>
        </v-col>

        <v-col
          cols="12"
          class="px-5 my-0"
        >
          <div class="d-flex item" />
          <div class="d-flex px-0 item my-2">
            <div class="finding-label text--caption primary--text font-weight-bold">
              Proposed Completion Date
            </div>
            <div class="finding-value">{{
              finding.acbProposedCompDate
              ? formatDate(finding.acbProposedCompDate)
              : '-'
            }}</div>
          </div>
        </v-col>
      </slot>

      <slot name="cas" :finding="finding">
        <v-col
          cols="12"
          class="px-5 my-0"
        >
          <div class="d-flex item" />
          <div class="d-flex px-0 item my-2">
            <div class="finding-label text--caption primary--text font-weight-bold">
              Summary of Corrective Action Taken
            </div>
            <div class="finding-value">{{ finding.acbCA || '-' }}</div>
          </div>
        </v-col>

        <v-col
          cols="12"
          class="px-5 my-0"
        >
          <div class="d-flex item" />
          <div class="d-flex px-0 item my-2">
            <div class="finding-label text--caption primary--text font-weight-bold">
              Completion Date
            </div>
            <div class="finding-value">{{
              finding.acbCompDate
              ? formatDate(finding.acbCompDate)
              : '-'
            }}</div>
          </div>
        </v-col>
      </slot>

      <v-col
        v-if="finding.acbCloseDate"
        cols="12"
        class="px-5 my-0"
      >
        <div class="d-flex item" />
        <div class="d-flex px-0 item my-2">
          <div class="finding-label text--caption primary--text font-weight-bold">
            Closed Date
          </div>
          <slot name="acbCloseDate" :finding="finding">
            <div class="finding-value">{{ formatDate(finding.acbCloseDate) }}</div>
          </slot>
        </div>
      </v-col>

      <v-col
        v-if="finding.acbClosedBy"
        cols="12"
        class="px-5 my-0"
      >
        <div class="d-flex item" />
        <div class="d-flex px-0 item my-2">
          <div class="finding-label text--caption primary--text font-weight-bold">
            Closed By
          </div>
          <slot name="acbClosedBy" :finding="finding">
            <div class="finding-value">{{
              finding.acbClosedBy
            }}</div>
          </slot>
        </div>
      </v-col>

      <slot name="notes" :finding="finding">
        <v-col
          v-if="finding.acbNotes"
          cols="12"
          class="px-5 my-0"
        >
          <div class="d-flex item" />
          <div class="d-flex px-0 item my-2">
            <div class="finding-label text--caption primary--text font-weight-bold">
              Notes
            </div>
              <div class="finding-value">{{finding.acbNotes || '-' }}</div>
          </div>
        </v-col>
      </slot>

    </v-row>
  </v-container>
</template>

<style scoped>
  .item{
    font-size: 0.92rem;
    white-space: pre-line;
  }
  .item:not(:last-child){
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }
  .finding-label{
    width: 125px;
    min-width: 125px;
    max-width: 125px;
  }
</style>

<script>
const defaultRedacted = ['_id', 'metadata', 'acbFindingFile']

import { fields } from "./field-definitions"

export default {
  props: {
    finding: {
      type: Object,
      default () { return {} }
    },
    redacted: {
      type: Array,
      default () { return [] }
    }
  },
  computed: {
    printableFields () {
      const r = [...defaultRedacted, ...this.redacted]
      return fields
      .filter(f => {
        return r.indexOf(f.field) === -1
      })
    },
  },
  methods: {
    formatDate(date) {
      const d = d instanceof Date ? date : new Date(date)
      return d.toIACSformat()
    }
  }
}
</script>
