<template>
  <v-container fluid class="pb-16">
    <PageTitle />
    <div class="d-flex flex-grow-1">
      <div
        style="width:80px;min-width:80px;max-width:80px"
      >
        <YearList
          :selected="year"
          @select="selectYear"
        />
      </div>
      <div
        class="pl-3 flex flex-grow-1"
      >
        <div class="d-flex align-center mt-2 mb-5 justify-space-between">
          <RadioGroup
            field="type"
            :column="false"
            :items="typeOptions"
            :value="findingType"
            @change="selectType"
          />
        </div>
        <DataTable
          :cols="cols"
          :data="items"
          @selectOne="view"
          @sort="sort"
        />
        <DocumentDrawer
          hide-print
          :open="ddo"
          :title="ddt"
          :width="960"
          :stateless="false"
          @close="ddo=false"
        >
          <SingleView
            v-if="selected"
            :id="selected._id"
          />
        </DocumentDrawer>
      </div>
    </div>
  </v-container>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

import { fields } from '@/models/finding'

import DocumentDrawer from '@/components/UI/document-drawer'
import RadioGroup from '@/components/UI/forms/radio-group'
import YearList from '@/components/UI/year-list'

import SingleView from './single-view-wrapper'

export default {
  components: {
    DocumentDrawer,
    RadioGroup,
    SingleView,
    YearList
  },
  data () {
    return {}
  },
  setup(props, context) {
    const { $store } = context.root
    const { Finding } = context.root.$FeathersVuex.api

    const acUserSelectedCS = computed (() => $store.state.acUserSelectedCS)
    const ddo = ref(false)
    const ddt = computed(() => {
      if (!selected.value) return ''
      return selected.value.acbFindingFile.replace('.xml', '')
    })
    const findingType = ref('All')
    const user = $store.state.auth.user
    const selected = ref(null)
    const year = ref(new Date().getFullYear())

    const typeOptions = [
      'All',
      'Non-Conformity',
      'Observation',
      'No Finding'
    ]

    const $select = [
      '_id',
      'acbClass',
      'acbACB',
      'acbAuditor',
      'acbDate',
      'acbFinding_Type',
      'acbFindingFile',
      'acbProposedCompDate',
      'acbCompDate',
      'acbCloseDate'
    ]

    const $sort = ref({ acbDate: -1 })
    const $limit = 500

    // need this to filter the local store
    // when an AC switches CS
    const classClause = computed(() => {
      return user.type === 'ac'
        ? { acbClass: user.assignedTo[acUserSelectedCS.value].value }
        : null
    })

    const dateClause = computed(() => ({
      acbDate: {
        $gte: new Date(`${year.value}-01-01`),
        $lt: new Date(`${year.value + 1}-01-01`)
      }
    }))

    const typeClause = computed(() => {
      return findingType.value !== 'All'
        ? { acbFinding_Type: findingType.value }
        : null
    })

    const params = computed(() => {
      const obCutoff = new Date( Date.now() - (Number(3.154e+10) * 1.5) ) // 18 'months' in milliseconds
      // this query is used locally on the vuex store
      return {
        qid: 'archive',
        query: {
          $select,
          $sort: $sort.value,
          $limit,
          $or: [
            {
              $and: [
                { acbFinding_Type: 'Non-Conformity' },
                { acbCloseDate: { $ne: null } }
              ]
            },
            {
              $and: [
                { acbFinding_Type: 'Observation' },
                // closed or older than 18 months
                { $or: [
                  { acbCloseDate: { $ne: null } },
                  { acbDate: { $lt: obCutoff } }
                ]}
              ]
            },
            {
              acbFinding_Type: 'No Finding'
            }
          ],
          ...classClause.value,
          ...dateClause.value,
          ...typeClause.value
        }
      }
    })

    const { items } = useFind({
      model: Finding,
      params: params,
    })

    const cols = [
        'acbClass',
        'acbACB',
        'acbAuditor',
        'acbDate',
        'acbFinding_Type',
        'acbFindingFile',
        'acbProposedCompDate',
        'acbCompDate',
        'acbCloseDate'
      ]
      .map(c => fields.find(f => f.field === c))
      .map(f => ( { ...f, label: f.shortname } ))

    const selectType = item => {
      findingType.value = item.value
    }

    const selectYear = value => {
      year.value = value
    }

    const sort = value => {
      $sort.value = { ...value }
    }

    const view = ( item ) => {
      selected.value = item
      ddo.value = true
    }

    return {
      cols,
      ddo,
      ddt,
      items,
      findingType,
      selected,
      selectType,
      selectYear,
      sort,
      // total,
      typeOptions,
      view,
      year
    }
  }

}
</script>
